import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../css/style.css"

const pageQuery = graphql`
  {
    gcms {
      notas(
        where: { seccionUrl: futbol }
        orderBy: createdAt_DESC
        first: 3
        skip: 1
      ) {
        titulo
        url
        seccionUrl
        imagenes {
          url(
            transformation: {
              image: { resize: { fit: clip, width: 240 } }
              document: { output: { format: webp } }
            }
          )
        }
      }
    }
  }
`

const Topicmodule1 = () => {
  const {
    gcms: { notas },
  } = useStaticQuery(pageQuery)

  return (
    <section className="othernotes-section page-border">
      {notas.map(({ seccionUrl, url, imagenes, ...notas }) => {
        return (
          <div>
            <div className="grid-container-othernotes">
              <div className="othernotes-img-sec">
                <Link key={url} to={`/${seccionUrl}/${url}`}>
                  <img className="othernotes-img" alt="" src={imagenes.url} />
                </Link>
              </div>
              <div>
                <div className="othernotes-title-sec">
                  <Link
                    key={url}
                    to={`/${seccionUrl}/${url}`}
                    className="othernotes-title"
                  >
                    {notas.titulo}
                  </Link>
                </div>
              </div>
            </div>
            <div className="othernotes-span"></div>
          </div>
        )
      })}
    </section>
  )
}

export default Topicmodule1
