import React from "react"
import Topicmodule1 from "./topicmodule1"
import "../css/style.css"
import Topicmodule2 from "./topicmodule2"

export default function Topic1() {
  return (
    <section className="landingnote-section page-border">
      <div className="grid-container-landingnote">
        <div className="landingnote-note-sec">
          <div className="landingnote-h2-sec">
            <p className="othernotes-main-title">Titular</p>
          </div>
          <Topicmodule1 />
        </div>
        <div className="landingnote-note-sec">
          <div className="landingnote-h2-sec">
            <p className="othernotes-main-title space">Champions</p>
          </div>
          <Topicmodule2 />
        </div>
        <div className="landingnote-note-sec">
          <div className="landingnote-h2-sec">
            <p className="othernotes-main-title space">Fuera de Campo</p>
          </div>
          <Topicmodule1 />
        </div>
      </div>
    </section>
  )
}
