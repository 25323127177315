import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Main from "../components/main"
import Comunity from "../components/comunity"
import NotesFirst from "../components/notesfirst"
import Notessecond from "../components/notessecond"
import Topic1 from "../components/topic1"

const IndexPage = () => (
  <>
    <SEO title="Inicio" />
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <Main />
    <NotesFirst />
    <Topic1 />
    <Notessecond />
    <Comunity />
  </>
)

export default IndexPage
