import React from "react"
import "../css/style.css"
import "../css/swiper.css"
import banner from "../images/banner.jpg"
import Carrousel from "./carrousel"

const Main = () => {
  return (
    <section className="main-section">
      <div className="grid-container-main">
        <div className="main-banner">
          <img className="main-banner-img" alt="" src={banner} />
        </div>
        <div>
          <Carrousel />
        </div>
        <div className="main-banner">
          <img className="main-banner-img" alt="" src={banner} />
        </div>
      </div>
    </section>
  )
}

export default Main
