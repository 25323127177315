import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import "../css/style.css"

const pageQuery = graphql`
  {
    gcms {
      notas(
        stage: PUBLISHED
        where: { destacado: false }
        first: 3
        orderBy: createdAt_DESC
      ) {
        titulo
        mainTag
        seccionTitulo
        seccionUrl
        resumen
        url
        imagenes {
          url(
            transformation: {
              image: { resize: { fit: clip, width: 550 } }
              document: { output: { format: png } }
            }
          )
        }
      }
    }
  }
`

const NoteFirst = () => {
  const {
    gcms: { notas },
  } = useStaticQuery(pageQuery)

  return (
    <section className="landingnote-section page-border">
      <div className="grid-container-landingnote">
        {notas.map(
          ({ seccionTitulo, seccionUrl, mainTag, url, imagenes, ...notas }) => {
            return (
              <div className="landingnote-note-sec">
                <div className="landingnote-h2-sec">
                  <Link
                    key={url}
                    to={`/${seccionUrl}`}
                    className="landingnote-h2"
                  >
                    {seccionTitulo}
                  </Link>
                </div>
                <div className="landingnote-img-sec">
                  <Link key={url} to={`/${seccionUrl}/${url}`}>
                    <img
                      className="landingnote-img"
                      alt=""
                      src={imagenes.url}
                    />
                  </Link>
                </div>
                <Link
                  key={url}
                  to={`/${seccionUrl}/${url}`}
                  className="landingnote-h3"
                >
                  {notas.titulo}
                </Link>
                <p className="landingnote-text">{notas.resumen}</p>
              </div>
            )
          }
        )}
      </div>
    </section>
  )
}

export default NoteFirst
