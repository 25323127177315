import React from "react"
import { Helmet } from "react-helmet"
import "../css/style.css"
import Instagram from "./instagram"

export default function Comunity() {
  return (
    <section className="comunity-section page-border">
      <Helmet>
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charset="utf-8"
        ></script>
        <script src="https://apps.elfsight.com/p/platform.js" defer></script>
      </Helmet>
      <div className="grid-container-comunity">
        <div className="comunity-container">
          <Instagram />
        </div>
        <div className="comunity-container-2">
          <a
            class="twitter-timeline"
            href="https://twitter.com/muchofuchomx?ref_src=twsrc%5Etfw"
          >
            Tweets by muchofuchomx
          </a>{" "}
        </div>
      </div>
    </section>
  )
}
