import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper"
import "../css/style.css"
import "../css/swiper.css"

const pageQuery = graphql`
  {
    gcms {
      notas(where: { destacado: true }) {
        titulo
        mainTag
        seccionTitulo
        seccionUrl
        fechaDeCreacion
        resumen
        url
        imagenes {
          url(
            transformation: {
              image: { resize: { fit: clip, width: 1200 } }
              document: { output: { format: webp } }
            }
          )
        }
      }
    }
  }
`

SwiperCore.use([Autoplay, Pagination, Navigation])

var params = {
  slidesPerView: 1,
  loop: true,
  spaceBetween: 10,
  autoplay: {
    delay: 8000,
    disableOnInteraction: false,
  },
  grabCursor: true,
}

const Carrousel = () => {
  const {
    gcms: { notas },
  } = useStaticQuery(pageQuery)

  return (
    <section className="main-section">
      <Swiper {...params} pagination={{ clickable: true }}>
        {notas.map(({ seccionUrl, url, imagenes, ...notas }) => {
          return (
            <SwiperSlide>
              <div className="main-titulo">
                <div className="main-div-span"></div>
                <p className="main-div-fecha">{notas.fechaDeCreacion}</p>
                <Link className="main-div-seccion" to={`/${seccionUrl}`}>
                  {notas.seccionTitulo}
                </Link>
                <br />
                <Link className="main-div-titulo" to={`/${seccionUrl}/${url}`}>
                  {notas.titulo}
                </Link>
              </div>
              <Link className="main-swiper-link" to={`/${seccionUrl}/${url}`}>
                <img className="main-swiper-img" alt="" src={imagenes.url} />
              </Link>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </section>
  )
}

export default Carrousel
